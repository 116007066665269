<template>
  <div>
    <h1>{{ pageTitle }} </h1>
    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <!-- card 1 -->
        <b-card
          header="Today"
          title="Client Name"
        >
          <b-card-text>
            791 Leisure Lane, Santa Barbara, CA, 93101
            H: (123) 456-7890
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            Clock In
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageTitle: 'Attendance',
    }
  },
}
</script>

<style>
h1{
  margin: auto;
}
</style>
